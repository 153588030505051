* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
html {
	scroll-behavior: smooth;
}
body {
	overflow-x: hidden;
}
textarea,
input,
body {
	font-family: 'Source Sans Pro', sans-serif;
}
.MuiFilledInput-root{
	background-color: #8DC4F2 !important;
	border-radius: 13px !important;
	border: none !important;
	box-shadow: inset 2px 2px 6px rgba(0, 0, 0, 0.25);
}
.MuiFilledInput-root.Mui-disabled{
	background-color: transparent !important;
}
label.Mui-disabled {
	color: black !important;
	
}
input.MuiSelect-select.Mui-disabled {
	color: black !important;
}
input.Mui-disabled {
	background-color: #F0F0F0 !important;
	border-radius: 13px !important;
	border: none !important;
	color: black !important;
	box-shadow: none !important;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input.Mui-disabled.Mui-disabled.Mui-disabled {
	color: black !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	  -webkit-appearance: none;
	  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.eBGUbv{
	margin: 15px auto auto 5px !important;
}
.MuiInputBase-root {
	color: white !important;
  }
.MuiStepper-root {
	padding: 13px !important;
}
.p-growl.p-growl-topright {
	top: 80px !important;
}
.p-growl-message-error {
	background-color: #ff5c5c !important;
	color: #fff !important;
	text-align: left;
}
.MuiStepContent-root {
	margin-top: 0px !important;
	padding-left: 0px !important;
}
.p-growl-message-success {
	/* background-color: #66CC9D !important; */
	background-color: #7d8df5 !important;
	color: #fff !important;
	text-align: left;
}

.p-growl-icon-close.p-link {
	color: #fff !important;
}
.p-growl-image {
	color: #fff !important;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes topDown {
	from {
		opacity: 0;
		transform: translateY(-50px);
	}
	to {
		height: 62px;
		transform: translateY(0);
	}
}

@keyframes open {
	0% {
		max-width: 0px;
		opacity: 0;
		display: flex;
	}
	25% {
		opacity: 0.1;
	}
	100% {
		max-width: 400px;
		opacity: 1;
	}
}
@keyframes close {
	100% {
		max-width: 0px;
		opacity: 0;
		display: none;
	}
	25% {
		opacity: 0.1;
	}
	0% {
		max-width: 400px;
		opacity: 1;
	}
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	box-shadow: 0 0 0 30px white inset !important;
}
