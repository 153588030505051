.menu-btn {
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	cursor: pointer;
	background: #2e82c8;
	transition: all 0.7s ease-in-out;
	-webkit-box-shadow: -1px 8px 11px -2px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: -1px 8px 11px -2px rgba(0, 0, 0, 0.5);
	box-shadow: -1px 8px 11px -2px rgba(0, 0, 0, 0.5);
	/* border: 3px solid #fff; */
}
.sandwichContainer {
	position: -webkit-sticky; /* Safari */
	position: sticky;
	left: 20px;
	top: 75px;
	z-index: 2;
	width: 60px;
}
.menu-btn__burger {
	position: relative;
	width: 25px;
	height: 3px;
	background: #fff;
	border-radius: 5px;
	transition: all 0.5s ease-in-out;
}
.menu-btn__burger::before {
	content: '';
	position: absolute;
	width: 25px;
	height: 3px;
	background: #fff;
	border-radius: 5px;
	transition: all 0.5s ease-in-out;
	top: 8px;
}
.menu-btn__burger::after {
	content: '';
	position: absolute;
	width: 25px;
	height: 3px;
	background: #fff;
	border-radius: 5px;
	transition: all 0.5s ease-in-out;
	top: -8px;
}
.menu-btn__burger::before {
	transform: translateY(-16px);
}
.menu-btn__burger::after {
	transform: translateY(16px);
}
/* ANIMATION */
.menu-btn.open .menu-btn__burger {
	transform: translateX(-50px);
	background: transparent;
	box-shadow: none;
}
.menu-btn.open .menu-btn__burger::before {
	top: 0px;
	transform: rotate(45deg) translate(35px, -35px);
}
.menu-btn.open .menu-btn__burger::after {
	top: 0px;
	transform: rotate(-45deg) translate(35px, 35px);
}
